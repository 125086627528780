import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import './App.css'; 
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import githubLogo from 'images/githubLogo.png'

function App() {
  const [userCount, setUserCount] = useState(false);
  const [RID, setRID] = useState(11);
  const [StartDate, setStartDate] = useState(false);
  const [EndDate, setEndDate] = useState(false);
  const [passiveGrowthVar, setpassiveGrowthVar] = useState(false);
  const [BeginningValue, setBeginningValue] = useState(false);
  const [EndingValue, setEndingValue] = useState(false);
  const [TotalSalesGrowth, setTotalSalesGrowth] = useState(false);
  const [InfluencerEconomicFactorImpact, setInfluencerEconomicFactorImpact] = useState(false);
  const [factors, setfactors] = useState(false);
  var [PassiveTable, setPassiveTable] = useState(false);

  //Special handling to use localhost SAM API if running locally via npm start(make run)
  const apiUrl = (process.env.NODE_ENV !== 'development') ? 'https://' + process.env.REACT_APP_USER_API_DOMAIN + '/users' : process.env.REACT_APP_USER_API_URL_LOCAL_SAM


  function GenerateTable() {
    // Gate to catch if all params not filled
    if (!(factors)) {
      return null;
    }
    if (TotalSalesGrowth < 0) {
      return (
        <Typography variant='h5'>No further analysis as there is no positive growth to apportion between active and passive components</Typography>
      );
    }

    PassiveTable = [];
    for (let i = 0; i < Object.keys(factors).length; i++) {
      PassiveTable[i]={
        "Factor": factors[i][1],
        "BeginningValue": parseFloat(factors[i][5]),
        "EndingValue": parseFloat(factors[i][6]),
        "TotalGrowth": parseFloat(factors[i][3]*100),
        "Elasticity": parseFloat(factors[i][2]),
        "Impact": parseFloat(factors[i][4])
      };
    };
    console.log('PassiveTable: ', PassiveTable);
    // return (PassiveTable);
    return (
      <div className="App">
        <Typography >The passive appreciation estimate presented here is based on the data for a diversified collection of all businesses, nationwide, for each retail sector, The passive appreciation proportion for individual businesses may differ depending on the demographic and economic conditions specific to their area of operation. </Typography>
        <br/>
        <table>
          <tr>
            <th>Factor Name</th>
            <th>Beginning Value</th>
            <th>Ending Value</th>
            <th>Total Growth</th>
            <th>Elasticity</th>
            <th>Impact</th>
          </tr>
          {PassiveTable.map((val, key) => {
            return (
              <tr key={key}>
                <td>{val.Factor}</td>
                <td>{val.BeginningValue.toFixed(3)}</td>
                <td>{val.EndingValue.toFixed(3)}</td>
                <td>{val.TotalGrowth.toFixed(2)} %</td>
                <td>{val.Elasticity.toFixed(5)}</td>
                <td>{val.Impact.toFixed(6)}</td>
              </tr>
            )
          })}
        </table>
        {/* <br/>
        <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
        <Typography variant='h5'>Passive Growth: {parseFloat(passiveGrowthVar*100).toFixed(2)} %</Typography>
        <br/> */}
      </div>
    );
  }

  function pasiveGrowthPrintout(){
    if (TotalSalesGrowth > 0){
      //If less than than 0
      if (passiveGrowthVar < 0 ){
        return (
          <div className="App">
            <br/>
            <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
            <Typography variant='h5'>Passive Growth: 0 %</Typography>
            <br/>
          </div>
        )
      }
      //If greater than 100%
      if ( passiveGrowthVar > 1){
        return (
          <div className="App">
            <br/>
            <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
            <Typography variant='h5'>Passive Growth: 100 %</Typography>
            <br/>
            {/* <Typography variant='h6'>The economic environment impact is greater than the total sales growth in this retail sector.</Typography> */}
          </div>
        )
      }
      //If greater than 0 and less than 100%
      if (passiveGrowthVar < 1 && passiveGrowthVar > 0){
        return (
          <div className="App">
            <br/>
            <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
            <Typography variant='h5'>Passive Growth: {parseFloat(passiveGrowthVar*100).toFixed(2)} %</Typography>
            <br/>
          </div>
        )
      }
    }
    //If Passive Growth < 1 print
    // if (TotalSalesGrowth > 0 && passiveGrowthVar < 1){
    //   return (
    //     <div className="App">
    //       <br/>
    //       <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
    //       <Typography variant='h5'>Passive Growth: {parseFloat(passiveGrowthVar*100).toFixed(2)} %</Typography>
    //       <br/>
    //     </div>
    //   )
    // }
    //If Passive Growth > 1 then set to 100 and add statement
    // if (TotalSalesGrowth > 0 && passiveGrowthVar > 1){
    //   return (
    //     <div className="App">
    //       <br/>
    //       <Typography variant='h5'>Total Influencer Economic Factor Impact: {parseFloat(InfluencerEconomicFactorImpact*100).toFixed(2)} %</Typography>
    //       <Typography variant='h5'>Passive Growth: 100 %</Typography>
    //       <br/>
    //       {/* <Typography variant='h6'>The economic environment impact is greater than the total sales growth in this retail sector.</Typography> */}
    //     </div>
    //   )
    // }
    //If passive growth is negative
  }

  function FactorGrowthCheck() {
    if (InfluencerEconomicFactorImpact > TotalSalesGrowth && TotalSalesGrowth > 0){
      return (
        <Typography variant='h6'>The economic environment impact is greater than the total sales growth in this retail sector</Typography>
      )
    }
    if (passiveGrowthVar < 0 && TotalSalesGrowth > 0){
      return (
        <Typography variant='h6'>The retail sector revenues grew in spite of the negative economic environment.  All Growth for this period is active.</Typography>
      )
    }
  }

  //Prevent continuous reloading calling API each time
  useEffect(() => {
    if (RID && StartDate && EndDate){
      fetch(apiUrl+"?RID="+RID+"&StartDate="+StartDate+"&EndDate="+EndDate)
      .then(response => response.json())
      .then(response => {
        console.log(response)
        setUserCount(response['User count'])
        setpassiveGrowthVar(response['passiveGrowthVar'])
        setBeginningValue(response['BeginningValue'])
        setEndingValue(response['EndingValue'])
        setTotalSalesGrowth(response['TotalSalesGrowth'])
        setInfluencerEconomicFactorImpact(response['InfluencerEconomicFactorImpact'])
        setfactors(response['factors'])
        console.log('Length of Factors Array: ', Object.keys(factors).length)
        
      })
      .catch(err => {
        console.log(err);
      });
    }
  }, [RID, StartDate, EndDate] );
  
  return (
    <div className="App">
      <header className="App-header">
      <br/>
        <Container className='header' maxWidth='md'>
          <Typography variant='h2'>
            Passive Appreciation
          </Typography>
          <Typography variant='h5'>
          Dr. Ashok Abbott
          </Typography>
          <br/>
          <Typography variant='h5'>
          Starting Date: 
          </Typography>
          <form>
            <input type="month" id="start" name="start"
              min="1992-01" max="2020-12" onChange={StartDate => setStartDate(StartDate.target.value)} ></input>
          </form>
          <Typography variant='h5'>
          Ending Date: 
          </Typography>
          <input type="month" id="end" name="end"
            min="1992-01" max="2020-12" onChange={EndDate => setEndDate(EndDate.target.value)} ></input>
          <Typography variant='h5'>
          Retail Category: 
          </Typography>
          <select onChange={RID => setRID(RID.target.value)} name="RIs" id="RIs">
            <option value=" 11 ">  New Car Dealers </option>
            <option value=" 12 ">  Used Car Dealers </option>
            <option value=" 13 ">  Automotive Parts, Acc., and Tire Stores </option>
            <option value=" 14 ">  Furniture, Home Furn, Electronics, and Appliance Stores </option>
            <option value=" 15 ">  Furniture and Home Furnishings Stores </option>
            <option value=" 16 ">  Furniture Stores </option>
            <option value=" 17 ">  Home Furnishings Stores </option>
            <option value=" 18 ">  Floor Covering Stores </option>
            <option value=" 20 ">  Electronics and Appliance Stores </option>
            <option value=" 21 ">  Household Appliance Stores </option>
            <option value=" 23 ">  Building Mat. and Garden Equip. and Supplies Dealers </option>
            <option value=" 24 ">  Building Mat. and Supplies Dealers </option>
            <option value=" 26 ">  Hardware Stores </option>
            <option value=" 28 ">  Grocery Stores </option>
            <option value=" 29 ">  Supermarkets and Other Grocery (Except Convenience) Stores </option>
            <option value=" 30 ">  Beer, Wine, and Liquor Stores </option>
            <option value=" 31 ">  Health and Personal Care Stores </option>
            <option value=" 32 ">  Pharmacies and Drug Stores </option>
            <option value=" 33 ">  Gasoline Stations </option>
            <option value=" 34 ">  Clothing and Clothing Access. Stores </option>
            <option value=" 35 ">  Clothing Stores </option>
            <option value=" 37 ">  Women's Clothing Stores </option>
            <option value=" 38 ">  Family Clothing Stores </option>
            <option value=" 40 ">  Shoe Stores </option>
            <option value=" 41 ">  Jewelry Stores </option>
            <option value=" 42 ">  Sporting Goods, Hobby, Musical Instrument, and Book Stores </option>
            <option value=" 43 ">  Sporting Goods Stores </option>
            <option value=" 44 ">  Hobby, Toy, and Game Stores </option>
            <option value=" 45 ">  Book Stores </option>
            <option value=" 46 ">  General Merchandise Stores </option>
            <option value=" 47 ">  Department Stores </option>
            <option value=" 48 ">  Discount Dept. Stores </option>
            <option value=" 49 ">  Department Stores (Excl. Discount Department Stores) </option>
            <option value=" 50 ">  Other General Merchandise Stores </option>
            <option value=" 51 ">  Warehouse Clubs and Superstores </option>
            <option value=" 52 ">  All Other Gen. Merchandise Stores </option>
            <option value=" 53 ">  Miscellaneous Store Retailers </option>
            <option value=" 54 ">  Office Supplies, Stationery, and Gift Stores </option>
            <option value=" 55 ">  Office Supplies and Stationery Stores </option>
            <option value=" 56 ">  Gift, Novelty, and Souvenir Stores </option>
            <option value=" 57 ">  Used Merchandise Stores </option>
            <option value=" 58 ">  Nonstore Retailers </option>
            <option value=" 59 ">  Electronic Shopping and Mail-order Houses </option>
            <option value=" 60 ">  Fuel Dealers </option>
            <option value=" 61 ">  Food Services and Drinking Places </option>
            <option value=" 62 ">  Drinking Places </option>
            <option value=" 63 ">  Restaurants and Other Eating Places </option>
            <option value=" 64 ">  Full Service Restaurants </option>
            <option value=" 65 ">  Limited Service Eating Places </option>
          </select>
          <br/>
          <br/>
          <Typography>
          This demo shows the passive growth over the specified time frame.
          </Typography>
          <br/>
          <Typography variant='h6'>Total Sales In {StartDate}: {parseFloat(BeginningValue).toFixed(2)}</Typography>
          <Typography variant='h6'>Total Sales In {EndDate}: {parseFloat(EndingValue).toFixed(2)}</Typography>
          <Typography variant='h6'>Total Retail Category Sales Growth: {parseFloat(TotalSalesGrowth*100).toFixed(2)} %</Typography>
          <br/>
          {GenerateTable()}
          {/* {PrintTable()} */}
          {pasiveGrowthPrintout()}
          {FactorGrowthCheck()}
          <br/>
          <Typography className='visitorCounter'>Please contact "ashok.abbott@bizvalinc.com" with any questions/comments/suggestions</Typography>
          <Typography className='visitorCounter'>Visitor Count: {userCount}</Typography>
        </Container>
      </header>
    </div>
  );
}

export default App;
